/**
 * Sticky header
 *
 * A simple utility class for sticky headers.
 *
 *============================================================================*/

.sticky-header {
  position: sticky;
  z-index: 2;
  top: 0;

  display: flex;
  align-items: center;
}
