/**
 * Loading spinner
 *
 * A lightweight style for loading class on buttons and links
 *
 *============================================================================*/

a.loading,
button.loading {
  position: relative;
  color: transparent !important;
  background: white !important;
  pointer-events: none !important;
  user-select: none !important;
  -webkit-user-select: none !important;

  span {
    color: transparent !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid black;
    border-bottom-color: transparent;
    animation: loading .75s infinite;
  }

  @keyframes loading {
    from { transform: rotate(0); }
    to   { transform: rotate(360deg); }
  }
}
